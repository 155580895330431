import styled from 'styled-components';
import { rem } from 'polished';

const BrandDetailsHeader = styled.div`
    box-shadow: 0 16px 16px -18px rgba(0, 0, 0, 0.5);
    padding-bottom: ${rem(16)};
    position: relative;
    z-index: 2;
`;

export default BrandDetailsHeader;

import styled from 'styled-components';

const ProductFilterItemLabelContainer = styled.span`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export default ProductFilterItemLabelContainer;

import React, { useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import withSizes from 'react-sizes';
import { areEqual, FixedSizeList } from 'react-window';
import { useHasMounted } from '@luigiclaudio/ga-baseline-ui/helpers';
import { isSizes } from '@luigiclaudio/ga-baseline-ui/layout';
import { ThemeContext } from 'styled-components';
import productPropTypes from '../../../prop-types/productPropTypes';
import useCreateProductInLocation from '../../../services/useCreateProductInLocation';
import BrandContext from '../../context/BrandContext';
import ProductListContainer from './ProductListContainer';
import VirtualizedProductListItem from './VirtualizedProductListItem';
import handleOnHotClick from '../../../utils/handleOnHotClick';
import useAllProductUserCache from '../../../services/useAllProductUserCache';

const VirtualizedProductList = ({ itemHeight, itemWidth, itemCount, products, isOneColumn }) => {
    const {
        productFilterList,
        productListCurrentRowIndex,
        setProductListCurrentRowIndex,
        productTypeListOnSelection,
    } = useContext(BrandContext);
    const theme = useContext(ThemeContext);
    const hasMounted = useHasMounted();
    const list = useRef();
    const { values } = useFormikContext();

    const {
        allProductsUserFromClient,
        setAllProductsUserFromClient,
        createProduct,
        deleteProduct,
        user,
        pathname,
        client,
    } = useAllProductUserCache();

    useCreateProductInLocation();

    useEffect(() => {
        const productFilterListHasChanged =
            productFilterList &&
            Object.keys(productFilterList).length !== productTypeListOnSelection.length;

        if (list && productFilterListHasChanged) {
            list.current.scrollToItem(0);
        }
    }, [list, values, productFilterList, productTypeListOnSelection]);

    useEffect(() => {
        if (hasMounted && productListCurrentRowIndex && list) {
            list.current.scrollToItem(productListCurrentRowIndex, 'center');
            setProductListCurrentRowIndex(undefined);
        }
    }, [hasMounted, list]);

    const {
        gapV: oneColumnGapV,
        gapH: oneColumnGapH,
        width: oneColumnWidth,
        height: oneColumnHeight,
        gridMargin: oneColumnGridMargin,
    } = theme.site.productItem.oneColumn;

    const {
        gapV: multiColumnGapV,
        gapH: multiColumnGapH,
        width: multiColumnWidth,
        height: multiColumnHeight,
        gridMargin: multiColumnGridMargin,
    } = theme.site.productItem.multiColumn;

    let columnCount = 0;
    let rowCount = 0;
    let responsiveCardWidth = 0;
    let responsiveCardHeight = 0;
    let itemSize = 0;
    let responsiveItemWidth = 0;

    if (hasMounted) {
        columnCount = Math.floor((itemWidth - multiColumnGridMargin * 2) / multiColumnWidth);
        rowCount = Math.ceil(itemCount / columnCount);
        responsiveCardWidth =
            (itemWidth - multiColumnGridMargin * 2) / columnCount - multiColumnGapH * 2;
        responsiveCardHeight = multiColumnHeight;
        itemSize = multiColumnHeight + multiColumnGapV * 2;
        responsiveItemWidth = itemWidth - multiColumnGridMargin * 2;

        if (isOneColumn) {
            columnCount = Math.floor((itemWidth - oneColumnGridMargin * 2) / oneColumnWidth) || 1;
            rowCount = Math.ceil(itemCount / columnCount);
            responsiveCardWidth =
                (itemWidth - oneColumnGridMargin * 2) / columnCount - oneColumnGapH * 2;
            responsiveCardHeight = oneColumnHeight;
            itemSize = oneColumnHeight + oneColumnGapV * 2;
            responsiveItemWidth = itemWidth - oneColumnGridMargin * 2;
        }
    }

    const itemData = useMemo(
        () => ({
            columnCount,
            itemCount,
            cardWidth: responsiveCardWidth,
            cardHeight: responsiveCardHeight,
        }),
        [columnCount, itemCount, areEqual],
    );

    return (
        <FixedSizeList
            ref={list}
            height={itemHeight}
            itemCount={rowCount}
            itemSize={itemSize}
            width={responsiveItemWidth}
            itemData={{
                ...itemData,
                products,
                allProductsUser: allProductsUserFromClient?.allProducts?.data,
                onHotClick: (currentUrl, currentId) =>
                    handleOnHotClick({
                        currentUrl,
                        currentId,
                        currentProduct: products.find((item) => item.url === currentUrl),
                        setAllProductsUserFromClient,
                        allProductsUserFromClient,
                        client,
                        createProduct,
                        deleteProduct,
                        user,
                        pathname,
                    }),
            }}
            outerElementType={ProductListContainer}
        >
            {VirtualizedProductListItem}
        </FixedSizeList>
    );
};

const mapSizesToProps = ({ width }, { theme }) => {
    return {
        isOneColumn: isSizes.smView({ width }, { theme }),
    };
};

VirtualizedProductList.propTypes = {
    itemHeight: PropTypes.number.isRequired,
    itemCount: PropTypes.number.isRequired,
    itemWidth: PropTypes.number.isRequired,
    products: productPropTypes.products.isRequired,
    isOneColumn: PropTypes.bool,
};

VirtualizedProductList.defaultProps = {
    isOneColumn: false,
};

export default withSizes(mapSizesToProps)(VirtualizedProductList);

import styled, { css } from 'styled-components';
import { rem, darken } from 'polished';
import scrollbarStyles from '../../../styles/scrollbarStyles';

const ProductFilterListOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${({ hasContainer, typesAmount, theme }) =>
        hasContainer &&
        css`
            background-color: ${darken(0.01, theme.site.stage.backgroundColor)};
            height: ${`calc((90vh - ${rem(
                theme.header.height +
                    theme.site.brandSearch.height +
                    theme.site.productFilters.header.height +
                    theme.site.productFilters.searchInput.height +
                    theme.site.productFilters.container.innerPadding * 2 * typesAmount +
                    (theme.site.productFilters.container.spacing / 1.5) * typesAmount,
            )}) / ${typesAmount})`};
            overflow-x: hidden;
            overflow-y: auto;
            padding: ${rem(theme.site.productFilters.container.innerPadding)};
            ${scrollbarStyles({ scrollbarOffset: 4 })}
        `}
`;

export default ProductFilterListOptionsContainer;

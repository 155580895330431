import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import slugify from 'slugify';
import { useFormikContext, Field } from 'formik';
import { isEmpty } from 'lodash';
import { FormButtonGroup, FormCheckboxButton } from '@luigiclaudio/ga-baseline-ui/form';
import { SrLabel } from '@luigiclaudio/ga-baseline-ui/helpers';
import ProductFilterItemLabelContainer from './ProductFilterItemLabelContainer';
import ProductFilterItemIcon from './ProductFilterItemIcon';
import BrandContext from '../../context/BrandContext';
import filterProductTypeOptionsPropTypes from '../../../prop-types/filterProductTypeOptionsPropTypes';

const ProductFilterList = ({ filterProductTypeOptions, onChange }) => {
    const intl = useIntl();
    const {
        setProductTypeOnStage,
        productTypeOnStage,
        setProductFilterList,
        brandOnStage,
        brandDetailsOnStage,
        brandProductsOnStage,
    } = useContext(BrandContext);

    const { values, setFieldValue } = useFormikContext();
    const { productFilter } = values;

    useEffect(() => {
        const { products } = brandDetailsOnStage;
        if (isEmpty(products) && !isEmpty(brandProductsOnStage)) {
            setFieldValue('productFilter', {});
        }
    }, [brandDetailsOnStage]);

    useEffect(() => {
        const keys = Object.keys(productFilter);
        const filtered = keys.filter((key) => productFilter[key]);
        setProductTypeOnStage(filtered);
    }, [productFilter]);

    useEffect(() => {
        setProductFilterList(productFilter);
    }, [productFilter]);

    return (
        <Field
            component={FormButtonGroup}
            id="productFilter"
            name="productFilter"
            role="group"
            title={intl.formatMessage({ id: 'productFilterList.productFilterGroup.title' })}
            buttonType="list"
            buttonSkin="muted"
        >
            {filterProductTypeOptions.map((item) => {
                const { value, label } = item;
                const slugifyValue = slugify(value);
                return (
                    <Field
                        key={slugifyValue}
                        component={FormCheckboxButton}
                        id={slugifyValue}
                        name={`productFilter.${slugifyValue}`}
                        value={slugifyValue}
                        onChange={() => onChange()}
                    >
                        <ProductFilterItemLabelContainer>
                            <SrLabel as="span">{brandOnStage}</SrLabel>
                            {label}
                            <ProductFilterItemIcon
                                active={
                                    productTypeOnStage && productTypeOnStage.includes(slugifyValue)
                                }
                            />
                        </ProductFilterItemLabelContainer>
                    </Field>
                );
            })}
        </Field>
    );
};

ProductFilterList.propTypes = {
    filterProductTypeOptions:
        filterProductTypeOptionsPropTypes.filterProductTypeOptionsList.isRequired,
    onChange: PropTypes.func,
};

ProductFilterList.defaultProps = {
    onChange: () => {},
};

export default ProductFilterList;

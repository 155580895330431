import styled from 'styled-components';
import { rem } from 'polished';

const BrandDetailsContainer = styled.div`
    height: 100%;
    padding: ${rem(16)} ${rem(32)};
    position: relative;
`;

export default BrandDetailsContainer;

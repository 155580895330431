import styled, { css } from 'styled-components';
import { rem } from 'polished';

const BrandDetailsHeaderMainContainer = styled.div`
    ${({ headerOffset }) =>
        headerOffset &&
        css`
            padding-right: ${rem(headerOffset)};
        `}
`;

export default BrandDetailsHeaderMainContainer;

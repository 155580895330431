import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';

const ProductCatalogFiltersStatus = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    ${media.lg`
        justify-content: flex-start;    
    `}

    ${({ headerOffset }) =>
        headerOffset &&
        css`
            padding-right: ${rem(headerOffset)};
        `}
`;

export default ProductCatalogFiltersStatus;

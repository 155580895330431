import styled from 'styled-components';
import { rem } from 'polished';

const ProductFilterSearchClearContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: ${({ theme }) => rem(theme.site.productFilters.searchReset.width)};
    z-index: 1;
`;

export default ProductFilterSearchClearContainer;

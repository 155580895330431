import React from 'react';
import PropTypes from 'prop-types';
import { GaIcon } from '@luigiclaudio/ga-baseline-ui/icon';
import ProductFilterItemIconContainer from './ProductFilterItemIconContainer';

const ProductFilterItemIcon = ({ active }) => {
    const renderIcon = active ? 'x' : 'plus';

    return (
        <ProductFilterItemIconContainer>
            <GaIcon icon={renderIcon} size={16} />
        </ProductFilterItemIconContainer>
    );
};

ProductFilterItemIcon.propTypes = {
    active: PropTypes.bool,
};

ProductFilterItemIcon.defaultProps = {
    active: false,
};

export default ProductFilterItemIcon;

import styled from 'styled-components';
import { rem } from 'polished';

const ProductListNoResultsTitle = styled.div`
    display: flex;
    justify-content: center;
    left: 50%;
    padding: ${rem(80)} ${rem(32)};
    position: absolute;
    text-align: center;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
`;

export default ProductListNoResultsTitle;

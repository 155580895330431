import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { animated, useTransition } from 'react-spring';
import { Formik, Form } from 'formik';
import { merge } from 'lodash';
import BrandDetailsOverlay from './BrandDetailsOverlay';
import BrandDetailsContainer from './BrandDetailsContainer';
import BrandContext from '../../context/BrandContext';
import BrandDetailsContent from './BrandDetailsContent';
import OverlayActions from './OverlayActions';
import useCurrentFilters from '../../../utils/useCurrentFilters';

const BrandDetails = ({ noScroll }) => {
    const overlayActionsBind = useRef();
    const { brandDetailsOnStage } = useContext(BrandContext);
    const { currentCatalogFiltersOnStage } = useCurrentFilters();
    const { brand } = brandDetailsOnStage;

    const [showDetails, setShowDetails] = useState(false);
    const [locationCatalogFilter, setLocationCatalogFilter] = useState();

    useEffect(() => {
        setLocationCatalogFilter(currentCatalogFiltersOnStage);
    }, [useCurrentFilters]);

    useEffect(() => {
        setShowDetails(brand);
    }, [brand]);

    useEffect(() => {
        if (noScroll) {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (typeof window !== 'undefined') {
                const noScrollWindow = () => {
                    if (showDetails) {
                        window.scrollTo(0, scrollTop);
                    }
                };

                window.addEventListener('scroll', noScrollWindow, true);

                return () => window.removeEventListener('scroll', noScrollWindow, true);
            }
            return null;
        }
        return undefined;
    }, [noScroll, showDetails]);

    const transition = useTransition(showDetails, {
        from: { opacityOverlay: 0 },
        enter: { opacityOverlay: 1 },
        leave: { opacityOverlay: 0 },
    });

    const AnimatedOverlay = animated(BrandDetailsOverlay);

    const overlayActions = [];

    const currentOverlayActionsWidth = overlayActionsBind.current?.getBoundingClientRect().width;

    const initialValues = { sortByPrice: 'highToLow', discounted: false, filterByGroup: 'all' };

    return (
        <>
            {transition(({ opacityOverlay }, item) => {
                return (
                    item && (
                        <AnimatedOverlay style={{ opacity: opacityOverlay }}>
                            <Formik initialValues={merge(initialValues, locationCatalogFilter)}>
                                {() => {
                                    return (
                                        <Form noValidate style={{ height: '100%' }}>
                                            <BrandDetailsContainer>
                                                <BrandDetailsContent
                                                    overlayActionsOffset={
                                                        currentOverlayActionsWidth
                                                    }
                                                />
                                                <OverlayActions
                                                    actions={overlayActions}
                                                    ref={overlayActionsBind}
                                                    disabled={!showDetails}
                                                    showActions
                                                />
                                            </BrandDetailsContainer>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </AnimatedOverlay>
                    )
                );
            })}
        </>
    );
};

BrandDetails.propTypes = {
    noScroll: PropTypes.bool,
};

BrandDetails.defaultProps = {
    noScroll: true,
};

export default BrandDetails;

import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { H2 } from '@luigiclaudio/ga-baseline-ui/typography';

const BrandDetailsHeaderMainTitle = styled(H2)`
    ${({ headerOffset }) =>
        headerOffset &&
        css`
            padding-right: ${rem(headerOffset)};
        `};
`;

export default BrandDetailsHeaderMainTitle;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';
import withSizes from 'react-sizes';
import { isSizes } from '@luigiclaudio/ga-baseline-ui/layout';
import { TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import { H6 } from '@luigiclaudio/ga-baseline-ui/typography';
import {
    FormButtonGroup,
    FormRadioButton,
    FormCheckboxButton,
} from '@luigiclaudio/ga-baseline-ui/form';
import { GaIcon } from '@luigiclaudio/ga-baseline-ui/icon';
import { Button } from '@luigiclaudio/ga-baseline-ui/button';
import { ModalContext } from '@luigiclaudio/ga-baseline-ui/modal';
import BrandTitle from '../BrandTitle';
import BrandDetailsHeaderMainContainer from './BrandDetailsHeaderMainContainer';
import BrandDetailsHeaderMainTitle from './BrandDetailsHeaderMainTitle';
import ProductListSortFilterContainer from './ProductListSortFilterContainer';
import ProductListSortFilterLabel from './ProductListSortFilterLabel';
import ProductListSortFiltersContainer from './ProductListSortFiltersContainer';
import BrandDetailsHeaderAside from './BrandDetailsHeaderAside';
import BrandContext from '../../context/BrandContext';
import ProductCatalogFiltersStatus from '../productCatalogFilters/ProductCatalogFiltersStatus';
import ProductCatalogModalFilters from '../ProductCatalogModalFilters';

const BrandDetailsHeaderMain = ({ headerOffset, productsAmount, isLg, isXs }) => {
    const intl = useIntl();
    const { brandOnStage, brandProductsOnStage, productTypeOnStage } = useContext(BrandContext);
    const theme = useContext(ThemeContext);
    const { showModal } = useContext(ModalContext);

    const sortHighToLowIcon = theme.site.sort.icon.highToLow;
    const sortLowToHighIcon = theme.site.sort.icon.lowToHigh;
    const discountedIcon = theme.site.sort.icon.discounted;
    const sortIconSize = theme.site.sort.iconSize;

    const disabledGroupFilter = (group, products) =>
        !products?.find((item) => item.group === group);
    const disabledMenFilter = disabledGroupFilter('men', brandProductsOnStage);
    const disabledWomenFilter = disabledGroupFilter('women', brandProductsOnStage);

    return (
        <>
            <BrandDetailsHeaderMainContainer>
                <TransitionItem inProp isFullWidth={false}>
                    <BrandDetailsHeaderMainTitle headerOffset={headerOffset}>
                        <BrandTitle>{brandOnStage}</BrandTitle>
                    </BrandDetailsHeaderMainTitle>
                </TransitionItem>
                {!isLg && <ProductCatalogFiltersStatus headerOffset={headerOffset} />}
            </BrandDetailsHeaderMainContainer>
            <TransitionItem
                inProp
                unmountOnExit
                mountOnEnter
                transitionTimeout={{ enter: 100 }}
                isFullWidth={false}
            >
                <BrandDetailsHeaderAside>
                    <ProductListSortFiltersContainer>
                        <ProductListSortFilterContainer>
                            <Field
                                component={FormButtonGroup}
                                id="filterByGroup"
                                name="filterByGroup"
                                buttonSkin="muted"
                                buttonSize="sm"
                                role="radiogroup"
                                title={intl.formatMessage({
                                    id: 'brandDetails.filterByGroup.label',
                                })}
                            >
                                <Field
                                    component={FormRadioButton}
                                    id="filterByGroupAll"
                                    name="filterByGroup"
                                    value="all"
                                    aria-label={intl.formatMessage({
                                        id: 'brandDetails.filterByGroup.all.button.label',
                                    })}
                                >
                                    {intl.formatMessage({
                                        id: 'brandDetails.filterByGroup.all.button.label',
                                    })}
                                </Field>
                                <Field
                                    component={FormRadioButton}
                                    id="filterByGroupWomen"
                                    name="filterByGroup"
                                    value="women"
                                    disabled={disabledWomenFilter}
                                    aria-label={intl.formatMessage({
                                        id: 'brandDetails.filterByGroup.women.button.label',
                                    })}
                                >
                                    {intl.formatMessage({
                                        id: 'brandDetails.filterByGroup.women.button.label',
                                    })}
                                </Field>
                                <Field
                                    component={FormRadioButton}
                                    id="filterByGroupMen"
                                    name="filterByGroup"
                                    value="men"
                                    disabled={disabledMenFilter}
                                    aria-label={intl.formatMessage({
                                        id: 'brandDetails.filterByGroup.men.button.label',
                                    })}
                                >
                                    {intl.formatMessage({
                                        id: 'brandDetails.filterByGroup.men.button.label',
                                    })}
                                </Field>
                            </Field>
                        </ProductListSortFilterContainer>
                        <ProductListSortFilterContainer>
                            {!isLg && (
                                <ProductListSortFilterLabel colorValue="muted">
                                    <FormattedMessage id="brandDetails.sortByPrice.label" />
                                </ProductListSortFilterLabel>
                            )}
                            <Field
                                component={FormButtonGroup}
                                id="sortByPrice"
                                name="sortByPrice"
                                buttonSkin="muted"
                                buttonSize="sm"
                                role="radiogroup"
                                title={intl.formatMessage({
                                    id: 'brandDetails.sortByPrice.label',
                                })}
                            >
                                <Field
                                    component={FormRadioButton}
                                    id="sortByPriceHighToLow"
                                    name="sortByPrice"
                                    value="highToLow"
                                    aria-label={intl.formatMessage({
                                        id: 'brandDetails.sortByPrice.highToLow.button.label',
                                    })}
                                >
                                    <GaIcon icon={sortHighToLowIcon} size={sortIconSize} />
                                </Field>
                                <Field
                                    component={FormRadioButton}
                                    id="sortByPriceLowToHigh"
                                    name="sortByPrice"
                                    value="lowToHigh"
                                    aria-label={intl.formatMessage({
                                        id: 'brandDetails.sortByPrice.lowToHigh.button.label',
                                    })}
                                >
                                    <GaIcon icon={sortLowToHighIcon} size={sortIconSize} />
                                </Field>
                            </Field>
                        </ProductListSortFilterContainer>
                        <ProductListSortFilterContainer>
                            {!isLg && (
                                <ProductListSortFilterLabel colorValue="muted">
                                    <FormattedMessage id="brandDetails.discounted.label" />
                                </ProductListSortFilterLabel>
                            )}
                            <Field
                                component={FormButtonGroup}
                                id="filterDiscounted"
                                name="filterDiscounted"
                                buttonSkin="muted"
                                buttonSize="sm"
                                role="group"
                                title={intl.formatMessage({
                                    id: 'brandDetails.discounted.label',
                                })}
                            >
                                <Field
                                    component={FormCheckboxButton}
                                    id="discounted"
                                    name="discounted"
                                    aria-label={intl.formatMessage({
                                        id: 'brandDetails.discounted.button.label',
                                    })}
                                >
                                    <GaIcon icon={discountedIcon} size={sortIconSize} />
                                </Field>
                            </Field>
                        </ProductListSortFilterContainer>
                        {isLg && (
                            <ProductListSortFilterContainer>
                                <Button
                                    icon="filter"
                                    skin="muted"
                                    btnType={isEmpty(productTypeOnStage) ? 'outlined' : 'default'}
                                    onClick={() => showModal(ProductCatalogModalFilters)}
                                    aria-label={intl.formatMessage({
                                        id: 'productFilter.productTypes.button.filter.label',
                                    })}
                                    type="button"
                                />
                            </ProductListSortFilterContainer>
                        )}
                    </ProductListSortFiltersContainer>
                    {!isXs && productsAmount && (
                        <ProductListSortFilterContainer>
                            <H6 color="muted">
                                <FormattedMessage
                                    id="brandDetails.currentAmount"
                                    values={{
                                        amount: intl.formatNumber(productsAmount),
                                    }}
                                />
                            </H6>
                        </ProductListSortFilterContainer>
                    )}
                </BrandDetailsHeaderAside>
            </TransitionItem>
        </>
    );
};

const mapSizesToProps = ({ width }, { theme }) => {
    return {
        isLg: isSizes.lgView({ width }, { theme }),
        isXs: isSizes.xsView({ width }, { theme }),
    };
};

BrandDetailsHeaderMain.propTypes = {
    headerOffset: PropTypes.number,
    productsAmount: PropTypes.number,
    isLg: PropTypes.bool,
    isXs: PropTypes.bool,
};

BrandDetailsHeaderMain.defaultProps = {
    headerOffset: undefined,
    productsAmount: undefined,
    isLg: false,
    isXs: false,
};

export default withSizes(mapSizesToProps)(BrandDetailsHeaderMain);

import styled from 'styled-components';
import { rem } from 'polished';

const BrandDetailsHeaderAside = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    /* margin-top: ${rem(16)}; */
`;

export default BrandDetailsHeaderAside;

import styled from 'styled-components';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';
import { rem } from 'polished';

const ProductListFadeContainer = styled.div`
    background-color: ${({ theme }) => theme.site.stage.backgroundColor};
    height: ${({ headerOffset, theme }) =>
        headerOffset &&
        `calc(98vh - ${headerOffset + theme.header.height + theme.site.brandSearch.height}px)`};
    margin: 0 -${rem(24)};
    position: relative;

    ${media.sm`
        height: ${({ headerOffset, theme }) =>
            headerOffset &&
            `calc(95vh - ${headerOffset + theme.header.height + theme.site.brandSearch.height}px)`};
            margin: 0;
    `}

    ${media.lg`
        height: ${({ headerOffset, theme }) =>
            headerOffset &&
            `calc(95vh - ${
                headerOffset +
                theme.header.height +
                theme.site.brandSearch.height +
                theme.site.productCatalog.spacing
            }px)`};
    `}

    &:after {
        background-image: linear-gradient(
            to bottom,
            transparent 10%,
            ${({ theme }) => theme.site.stage.backgroundColor} 50%
        );
        bottom: -${rem(1)};
        content: '';
        height: ${rem(24)};
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }
`;

export default ProductListFadeContainer;

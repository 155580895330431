import { transparentize } from 'polished';
import styled from 'styled-components';

const ModalProductCatalogFiltersFadeContainer = styled.div`
    position: relative;

    &:after,
    &:before {
        content: '';
        height: 20px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 2;
    }

    &:after {
        background-image: linear-gradient(
            to bottom,
            ${({ theme }) => transparentize(1, theme.modal.backgroundColor)} 10%,
            ${({ theme }) => theme.modal.backgroundColor} 50%
        );
        bottom: -2px;
    }

    &:before {
        background-image: linear-gradient(
            to top,
            ${({ theme }) => transparentize(1, theme.modal.backgroundColor)} 10%,
            ${({ theme }) => theme.modal.backgroundColor} 50%
        );
        top: -2px;
    }
`;

export default ModalProductCatalogFiltersFadeContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import ProductCatalogFiltersStatusContainer from './ProductCatalogFiltersStatusContainer';
import ProductTypesSelection from './ProductTypesSelection';

const ProductCatalogFiltersStatus = ({ headerOffset }) => {
    return (
        <ProductCatalogFiltersStatusContainer headerOffset={headerOffset}>
            <TransitionItem inProp transitionTimeout={{ enter: 50 }} isFullWidth={false}>
                <ProductTypesSelection />
            </TransitionItem>
        </ProductCatalogFiltersStatusContainer>
    );
};

ProductCatalogFiltersStatus.propTypes = {
    headerOffset: PropTypes.number,
};

ProductCatalogFiltersStatus.defaultProps = {
    headerOffset: undefined,
};

export default ProductCatalogFiltersStatus;

import PropTypes from 'prop-types';

const filterProductTypeOptions = PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
});

const filterProductTypeOptionsList = PropTypes.arrayOf(filterProductTypeOptions);

export default { filterProductTypeOptions, filterProductTypeOptionsList };

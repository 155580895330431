import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { cssHelpers } from '@luigiclaudio/ga-baseline-ui/helpers';

const ProductFilterListHeader = styled.div`
    ${cssHelpers.clearfix}
    align-items: center;
    display: flex;
    justify-content: flex-start;
    min-height: ${({ theme }) => rem(theme.site.productFilters.header.height)};
    position: relative;
    z-index: 1;

    ${({ hasContainer }) =>
        hasContainer &&
        css`
            box-shadow: ${({ theme }) => theme.base.boxShadow.elevationDefault};
            padding: 0 ${rem(16)};
        `}

    ${({ hasContainer }) =>
        !hasContainer &&
        css`
            border-bottom: 1px solid ${({ theme }) => theme.base.grays.grayLight};
            padding: 0 ${rem(8)};
        `}
`;

export default ProductFilterListHeader;

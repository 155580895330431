import styled, { css } from 'styled-components';
import { rem } from 'polished';

const ProductFilterListOuterContainer = styled.div`
    ${({ hasContainer }) =>
        hasContainer &&
        css`
            padding: ${({ theme }) => rem(theme.site.productFilters.container.spacing / 3)}
                ${({ theme }) => rem(theme.site.productFilters.container.spacing)};
        `}

    ${({ hasContainer }) =>
        !hasContainer &&
        css`
            padding: 0 ${({ theme }) => rem(theme.site.productFilters.container.spacing / 3)}
                ${({ theme }) => rem(theme.site.productFilters.container.spacing / 2)}
                ${({ theme }) => rem(theme.site.productFilters.container.spacing / 3)};
        `}

    ${({ isEmpty }) =>
        isEmpty &&
        css`
            display: none;
        `}
`;

export default ProductFilterListOuterContainer;

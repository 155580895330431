import styled from 'styled-components';
import { rem } from 'polished';

const ProductFilterNoResults = styled.div`
    left: 0;
    padding: ${({ theme }) => rem(theme.site.productFilters.searchInput.spacing)};
    position: absolute;
    top: ${({ theme }) => rem(theme.site.productFilters.searchInput.height)};
    width: ${({ theme }) => rem(theme.site.productFilters.width)};
`;

export default ProductFilterNoResults;

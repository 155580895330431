import React, { useContext } from 'react';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { H6 } from '@luigiclaudio/ga-baseline-ui/typography';
import ProductTypesSelectionContainer from './ProductTypesSelectionContainer';
import ProductTypesSelectionLabel from './ProductTypesSelectionLabel';
import productGroupData from '../../../../data/productGroupData';
import BrandContext from '../../../context/BrandContext';

const ProductTypesSelection = () => {
    const { productTypeOnStage, catalogFiltersOnStage } = useContext(BrandContext);

    return (
        <ProductTypesSelectionContainer>
            <H6 color="muted">
                <FormattedMessage
                    id="productFilter.productTypes.label"
                    values={{
                        productTypes: (
                            <ProductTypesSelectionLabel>
                                {!isEmpty(productTypeOnStage) ? (
                                    `${productTypeOnStage.join(', ')}`
                                ) : (
                                    <FormattedMessage id="productFilter.productTypes.all.label" />
                                )}
                            </ProductTypesSelectionLabel>
                        ),
                    }}
                />
                {productGroupData.some(
                    (group) => catalogFiltersOnStage?.filterByGroup === group,
                ) && (
                    <FormattedMessage
                        id="productFilter.productFor.label"
                        values={{
                            group: (
                                <ProductTypesSelectionLabel>
                                    <FormattedMessage
                                        id={`productFilter.productFor.${catalogFiltersOnStage.filterByGroup}.label`}
                                    />
                                </ProductTypesSelectionLabel>
                            ),
                        }}
                    />
                )}
                {catalogFiltersOnStage?.discounted && (
                    <FormattedMessage
                        id="productFilter.productOnSale.label"
                        values={{
                            sale: (
                                <ProductTypesSelectionLabel>
                                    <FormattedMessage id="productFilter.productOnSale.sale.label" />
                                </ProductTypesSelectionLabel>
                            ),
                        }}
                    />
                )}
            </H6>
        </ProductTypesSelectionContainer>
    );
};

export default ProductTypesSelection;

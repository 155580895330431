import styled, { css } from 'styled-components';
import { rem } from 'polished';

const ProductFilterListContainer = styled.div`
    ${({ hasContainer }) =>
        hasContainer &&
        css`
            background-color: ${({ theme }) => theme.site.stage.backgroundColor};
            border-radius: ${({ theme }) => rem(theme.base.borderRadius.md)};
            box-shadow: ${({ theme }) => theme.base.boxShadow.elevationSmall};
        `}
`;

export default ProductFilterListContainer;

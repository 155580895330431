import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';

const OverlayActionsContainer = styled.div`
    display: flex;
    margin: 0 -${rem(8)};
    position: absolute;
    right: ${rem(24)};
    top: ${rem(24)};
    z-index: 2;

    ${media.sm`
        right: ${rem(32)};
        top: ${rem(24)};
    `}

    ${({ isFullScreen }) =>
        isFullScreen &&
        css`
            ${media.md`
                right: ${rem(32)};
                top: ${rem(32)};
            `}
        `}
`;

export default OverlayActionsContainer;

import styled from 'styled-components';
import { rem } from 'polished';
import { h6Styles } from '@luigiclaudio/ga-baseline-ui/typography';

const ProductListSortFilterLabel = styled.span`
    ${h6Styles}
    margin-right: ${rem(8)};
`;

export default ProductListSortFilterLabel;

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, Field } from 'formik';
import { useIntl } from 'react-intl';
import { FormInput } from '@luigiclaudio/ga-baseline-ui/form';
import { Button } from '@luigiclaudio/ga-baseline-ui/button';
import { TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import ProductFilterSearchContainer from './ProductFilterSearchContainer';
import ProductFilterSearchInputContainer from './ProductFilterSearchInputContainer';
import ProductFilterSearchClearContainer from './ProductFilterSearchClearContainer';
import ProductFilterSearchClearButton from './ProductFilterSearchClearButton';
import BrandContext from '../../context/BrandContext';

const ProductFilterSearch = ({ onChange }) => {
    const intl = useIntl();
    const { values, resetForm, initialValues } = useFormikContext();
    const { productSearchFilter } = values;
    const { setSearchFilterTypes, productTypeOnStage, brandDetailsOnStage } = useContext(
        BrandContext,
    );

    useEffect(() => {
        setSearchFilterTypes(productSearchFilter);
    }, [productSearchFilter]);

    useEffect(() => {
        resetForm(initialValues);
    }, [productTypeOnStage, brandDetailsOnStage]);

    return (
        <ProductFilterSearchContainer>
            <ProductFilterSearchInputContainer>
                <Field
                    component={FormInput}
                    id="productSearchFilter"
                    name="productSearchFilter"
                    label={intl.formatMessage({
                        id: 'productFilterSearch.productFilterSearchInput.label',
                    })}
                    type="text"
                    onChange={() => onChange()}
                />
            </ProductFilterSearchInputContainer>
            <ProductFilterSearchClearContainer>
                <TransitionItem
                    inProp={!!productSearchFilter}
                    transitionTimeout={{ enter: 50, exit: 50 }}
                >
                    <ProductFilterSearchClearButton>
                        <Button
                            icon="x"
                            arial-label={intl.formatMessage({
                                id: 'productFilterSearch.productFilterSearchClear.label',
                            })}
                            skin="muted"
                            btnType="outlined"
                            size="sm"
                            type="button"
                            onClick={() => resetForm(initialValues)}
                        />
                    </ProductFilterSearchClearButton>
                </TransitionItem>
            </ProductFilterSearchClearContainer>
        </ProductFilterSearchContainer>
    );
};

ProductFilterSearch.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default ProductFilterSearch;

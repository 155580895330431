import styled from 'styled-components';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';
import scrollbarStyles from '../../../styles/scrollbarStyles';

const ModalProductCatalogFiltersContainer = styled.div`
    height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    ${({ theme }) =>
        scrollbarStyles({
            scrollbarOffset: 16,
            containerBackgroundColor: theme.modal.backgroundColor,
        })}

    ${media.sm`
        height: 40vh;   
    `}
`;

export default ModalProductCatalogFiltersContainer;

import styled from 'styled-components';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';

const VirtualizedProductListItemContainer = styled.div`
    display: flex;
    justify-content: center;

    ${media.sm`
        justify-content: flex-start;

    `}
`;

export default VirtualizedProductListItemContainer;

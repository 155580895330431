import styled from 'styled-components';
import { rem } from 'polished';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';

const ProductFilterSearchContainer = styled.div`
    display: flex;
    min-height: ${({ theme }) => rem(theme.site.productFilters.searchInput.height)};
    padding: ${({ theme }) => rem(theme.site.productFilters.searchInput.spacing / 1.25)}
        ${({ theme }) => rem(theme.site.productFilters.searchInput.spacing)}
        ${({ theme }) => rem(theme.site.productFilters.searchInput.spacing / 2)};
    width: ${({ theme }) => rem(theme.site.productFilters.width)};

    ${media.xl`
        padding: ${({ theme }) => rem(theme.site.productFilters.searchInput.spacing)}
            ${({ theme }) => rem(theme.site.productFilters.searchInput.spacing)}
            ${({ theme }) => rem(theme.site.productFilters.searchInput.spacing / 2)};
    `}
`;

export default ProductFilterSearchContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { H5 } from '@luigiclaudio/ga-baseline-ui/typography';
import { TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import ProductListNoResultsContainer from './ProductListNoResultsContainer';
import ProductListNoResultsTitle from './ProductListNoResultsTitle';

const ProductListNoResults = ({ inProp }) => {
    return (
        <TransitionItem
            appear
            inProp={inProp}
            mountOnEnter
            unmountOnExit
            transitionTimeout={{ exit: 150 }}
        >
            <ProductListNoResultsContainer>
                <ProductListNoResultsTitle>
                    <H5 color="muted">
                        <FormattedMessage id="brandDetails.productList.noResults" />
                    </H5>
                </ProductListNoResultsTitle>
            </ProductListNoResultsContainer>
        </TransitionItem>
    );
};

ProductListNoResults.propTypes = {
    inProp: PropTypes.bool,
};

ProductListNoResults.defaultProps = {
    inProp: false,
};

export default ProductListNoResults;

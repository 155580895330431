import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import { ThemeContext } from 'styled-components';
import { isSizes } from '@luigiclaudio/ga-baseline-ui/layout';
import { ThemeModeContext } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import { useHasMounted } from '@luigiclaudio/ga-baseline-ui/helpers';
import ProductItem from '../ProductItem';
import VirtualizedProductListItemContainer from './VirtualizedProductListItemContainer';
import productPropTypes from '../../../prop-types/productPropTypes';

const VirtualizedProductListItem = ({ data, index, style, isOneColumn }) => {
    const theme = useContext(ThemeContext);
    const { themeMode } = useContext(ThemeModeContext);
    const hasMounted = useHasMounted();

    const { gapV: oneColumnGapV, gapH: oneColumnGapH } = theme.site.productItem.oneColumn;
    const { gapV: multiColumnGapV, gapH: multiColumnGapH } = theme.site.productItem.multiColumn;

    let responsiveGapV = 0;
    let responsiveGapH = 0;

    if (hasMounted) {
        responsiveGapV = multiColumnGapV;
        responsiveGapH = multiColumnGapH;

        if (isOneColumn) {
            responsiveGapV = oneColumnGapV;
            responsiveGapH = oneColumnGapH;
        }
    }

    const { cardWidth, columnCount, itemCount, products, allProductsUser, onHotClick } = data;
    const { top, width, height } = style;

    // This is the range of cards visible on this row, given the current width:
    const startIndex = index * columnCount;
    const stopIndex = Math.min(itemCount - 1, startIndex + columnCount - 1);

    const cards = [];

    for (let i = startIndex; i <= stopIndex; i += 1) {
        const isHot = allProductsUser?.find((product) => product.url === products[i].url);

        cards.push(
            <ProductItem
                key={i}
                rowIndex={Math.floor(i / columnCount)}
                brand={products[i].brand}
                url={products[i].url}
                image={products[i].image}
                title={products[i].title}
                price={products[i].price}
                priceBeforeSale={products[i].priceBeforeSale}
                currentLocalPrice={products[i].currentLocalPrice}
                intlKeywords={products[i].intlKeywords}
                retailer={products[i].retailer}
                group={products[i].group}
                currency={products[i].currency}
                itemWidth={cardWidth}
                themeMode={themeMode}
                isHotId={isHot?.id}
                isNotId={undefined}
                onHotClick={onHotClick}
            />,
        );
    }

    return (
        <VirtualizedProductListItemContainer
            style={{
                ...style,
                top: top + responsiveGapV,
                width: `calc(${width} - ${responsiveGapH}px)`,
                height: height - responsiveGapV * 2,
            }}
        >
            {cards}
        </VirtualizedProductListItemContainer>
    );
};

const mapSizesToProps = ({ width }, { theme }) => {
    return {
        isOneColumn: isSizes.smView({ width }, { theme }),
    };
};

VirtualizedProductListItem.propTypes = {
    data: PropTypes.shape({
        cardWidth: PropTypes.number,
        columnCount: PropTypes.number,
        gapSize: PropTypes.number,
        itemCount: PropTypes.number,
        products: productPropTypes.products,
        allProductsUser: productPropTypes.userProducts,
        onHotClick: PropTypes.func,
    }).isRequired,
    index: PropTypes.number.isRequired,
    style: PropTypes.shape({
        height: PropTypes.number,
        left: PropTypes.number,
        position: PropTypes.string,
        right: PropTypes.number,
        top: PropTypes.number,
        width: PropTypes.string,
    }).isRequired,
    isOneColumn: PropTypes.bool,
};

VirtualizedProductListItem.defaultProps = {
    isOneColumn: false,
};

export default withSizes(mapSizesToProps)(VirtualizedProductListItem);

import { useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import BrandContext from '../components/context/BrandContext';
import productGroupData from '../data/productGroupData';
import productSortPriceData from '../data/productSortPriceData';

const useCurrentFilters = () => {
    const location = useLocation();
    const currentFilters = new URLSearchParams(location.search);

    const currentLocationFilters = currentFilters.get('currentFilters');
    const currentLocationGroup = currentFilters.get('currentGroup');
    const currentLocationSort = currentFilters.get('currentSort');
    const currentLocationDiscounted = currentFilters.get('currentDiscounted');

    const currentLocationDiscountedToBoolean = currentLocationDiscounted === 'true';

    const { productTypeOnStage, productTypeListOnSelection, catalogFiltersOnStage } = useContext(
        BrandContext,
    );

    const currentProductFilter = {};
    const currentCatalogFiltersOnStage = {};

    useEffect(() => {
        if (currentLocationFilters) {
            currentLocationFilters.split(',').forEach((currentItem) => {
                if (productTypeListOnSelection.some((listItem) => listItem === currentItem)) {
                    currentProductFilter[currentItem] = true;
                }
            });
        }

        if (currentLocationGroup) {
            currentCatalogFiltersOnStage.filterByGroup = productGroupData.some(
                (group) => currentLocationGroup === group,
            )
                ? currentLocationGroup
                : 'all';
            currentCatalogFiltersOnStage.sortByPrice = productSortPriceData.some(
                (sortPrice) => currentLocationSort === sortPrice,
            )
                ? currentLocationSort
                : 'highToLow';
            currentCatalogFiltersOnStage.discounted = currentLocationDiscounted
                ? currentLocationDiscountedToBoolean
                : false;
        }
    }, []);

    useEffect(() => {
        if (catalogFiltersOnStage) {
            const { filterByGroup, sortByPrice, discounted } = catalogFiltersOnStage;
            navigate(
                `?currentGroup=${filterByGroup}&currentSort=${sortByPrice}&currentDiscounted=${discounted}&currentFilters=${productTypeOnStage?.join()}`,
                { replace: true },
            );
        }
    }, [productTypeOnStage, catalogFiltersOnStage]);

    return {
        currentProductFilter,
        currentCatalogFiltersOnStage,
    };
};

export default useCurrentFilters;

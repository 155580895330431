import styled from 'styled-components';
import { rem } from 'polished';

const ProductListSortFilterContainer = styled.div`
    display: flex;
    margin-bottom: ${rem(8)};
    margin-right: ${rem(32)};
    margin-top: ${rem(8)};

    &:last-child {
        margin-right: 0;
    }
`;

export default ProductListSortFilterContainer;

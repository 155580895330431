import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { GaIcon } from '@luigiclaudio/ga-baseline-ui/icon';
import OverlayActionsContainer from './OverlayActionsContainer';
import OverlayAction from './OverlayAction';

const OverlayActions = forwardRef(({ actions, disabled, showActions }, ref) => {
    if (!showActions) {
        return null;
    }

    const theme = useContext(ThemeContext);

    return (
        <OverlayActionsContainer ref={ref}>
            {actions.map((item, index) => {
                const { onClick, icon, label } = item;
                return (
                    <OverlayAction
                        key={index}
                        onClick={onClick}
                        aria-label={label}
                        disabled={disabled}
                        type="button"
                    >
                        <GaIcon
                            icon={icon}
                            color={theme.base.grays.gray}
                            size={theme.site.brandDetails.closeIcon.size}
                        />
                    </OverlayAction>
                );
            })}
        </OverlayActionsContainer>
    );
});

OverlayActions.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            onClick: PropTypes.func,
            icon: PropTypes.string,
            label: PropTypes.string,
        }),
    ).isRequired,

    disabled: PropTypes.bool,
    showActions: PropTypes.bool,
};

OverlayActions.defaultProps = {
    disabled: false,
    showActions: true,
};

export default OverlayActions;

import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';

const countryUtils = {
    displayCountryName: (intl, countryCode) =>
        intl.formatDisplayName(countryCode, {
            type: 'region',
        }),
};

export default countryUtils;

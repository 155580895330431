import { rem } from 'polished';
import styled from 'styled-components';

const OverlayAction = styled.button`
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin: 0 ${rem(8)};
    opacity: 0.7;
    padding: 0;
    transition: opacity 0.15s ease-in-out;

    &:hover {
        opacity: 1;
    }

    &:focus {
        outline: none;
    }
`;

export default OverlayAction;

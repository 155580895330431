import styled from 'styled-components';
import { rem } from 'polished';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';
import scrollbarStyles from '../../../styles/scrollbarStyles';

const ProductListContainer = styled.div`
    margin: 0 ${({ theme }) => rem(theme.site.productItem.oneColumn.gridMargin)};
    overflow-y: scroll;
    position: relative;

    ${media.sm`
        margin: 0 ${({ theme }) => rem(theme.site.productItem.multiColumn.gridMargin)};
    `}

    ${scrollbarStyles}
`;

export default ProductListContainer;

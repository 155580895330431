import React from 'react';
import PropTypes from 'prop-types';
import { H6 } from '@luigiclaudio/ga-baseline-ui/typography';
import { FormattedMessage } from 'react-intl';
import ProductFilterListOuterContainer from '../ProductCatalogAsideFilters/ProductFilterListOuterContainer';
import ProductFilterListContainer from '../ProductCatalogAsideFilters/ProductFilterListContainer';
import ProductFilterListHeader from '../ProductCatalogAsideFilters/ProductFilterListHeader';
import categoryUtils from '../../../utils/categoryUtils';
import ProductFilterListOptionsContainer from '../ProductCatalogAsideFilters/ProductFilterListOptionsContainer';
import ProductFilterList from '../ProductCatalogAsideFilters/ProductFilterList';
import filterProductTypeOptionsPropTypes from '../../../prop-types/filterProductTypeOptionsPropTypes';

const ProductCatalogFilterList = ({
    type,
    typesAmount,
    filterProductTypeOptions,
    onChange,
    hasContainer,
}) => {
    const isEmptyList = !filterProductTypeOptions.length;

    return (
        <ProductFilterListOuterContainer
            hasContainer={hasContainer}
            isEmpty={isEmptyList}
            aria-hidden={isEmptyList}
        >
            <ProductFilterListContainer hasContainer={hasContainer}>
                <ProductFilterListHeader hasContainer={hasContainer}>
                    {!isEmptyList && (
                        <H6>
                            <FormattedMessage id={categoryUtils.filterCategoriesLabel(type)} />
                        </H6>
                    )}
                </ProductFilterListHeader>
                <ProductFilterListOptionsContainer
                    typesAmount={typesAmount}
                    hasContainer={hasContainer}
                >
                    <ProductFilterList
                        filterProductTypeOptions={filterProductTypeOptions}
                        onChange={onChange}
                    />
                </ProductFilterListOptionsContainer>
            </ProductFilterListContainer>
        </ProductFilterListOuterContainer>
    );
};

ProductCatalogFilterList.propTypes = {
    type: PropTypes.string.isRequired,
    typesAmount: PropTypes.number,
    filterProductTypeOptions:
        filterProductTypeOptionsPropTypes.filterProductTypeOptionsList.isRequired,
    onChange: PropTypes.func,
    hasContainer: PropTypes.bool,
};

ProductCatalogFilterList.defaultProps = {
    typesAmount: undefined,
    onChange: undefined,
    hasContainer: false,
};

export default ProductCatalogFilterList;

import styled from 'styled-components';
import { rem } from 'polished';

const BrandCountryProductTypesContainer = styled.div`
    align-items: center;
    display: flex;
    padding-bottom: ${rem(8)};
    text-align: right;
`;

export default BrandCountryProductTypesContainer;

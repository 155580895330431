import React, { useState, useContext, useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import BrandDetailsHeader from './BrandDetailsHeader';
import ProductListFadeContainer from './ProductListFadeContainer';
import BrandDetailsHeaderMain from './BrandDetailsHeaderMain';
import BrandDetailsHeaderContent from './BrandDetailsHeaderContent';
import VirtualizedProductList from './VirtualizedProductList';
import ProductListNoResults from './ProductListNoResults';
import brandUtils from '../../../utils/brandUtils';
import BrandContext from '../../context/BrandContext';

const BrandDetailsContent = ({ overlayActionsOffset }) => {
    const {
        brandDetailsOnStage,
        productTypeOnStage,
        setBrandDetailsOnStage,
        brandProductsOnStage,
        setCatalogFiltersOnStage,
    } = useContext(BrandContext);

    const { values } = useFormikContext();
    const { sortByPrice, discounted, filterByGroup } = values;

    const { products } = brandDetailsOnStage;

    const brandDetailsHeaderBind = useRef();

    const [currentProductList, setCurrentProductList] = useState();
    const [currentProductGroupList, setCurrentProductGroupList] = useState();
    const [brandDetailsHeaderHeight, setBrandDetailsHeaderHeight] = useState();

    useEffect(() => {
        setCatalogFiltersOnStage(values);
        return () => {
            setCatalogFiltersOnStage(undefined);
        };
    }, [values]);

    useEffect(() => {
        if (brandProductsOnStage) {
            setBrandDetailsOnStage({
                ...brandDetailsOnStage,
                products: brandUtils.productByProductTypeOnStage(
                    brandProductsOnStage,
                    productTypeOnStage,
                ),
            });
        }
    }, [productTypeOnStage]);

    useEffect(() => {
        if (products) {
            if (filterByGroup !== 'all') {
                setCurrentProductGroupList(products.filter((item) => item.group === filterByGroup));
            } else {
                setCurrentProductGroupList(products);
            }
        }
    }, [products, filterByGroup]);

    useEffect(() => {
        if (currentProductGroupList) {
            if (sortByPrice === 'highToLow') {
                setCurrentProductList(
                    brandUtils.sortProductByCurrentPriceHighToLow(currentProductGroupList),
                );
            }
            if (sortByPrice === 'lowToHigh') {
                setCurrentProductList(
                    brandUtils.sortProductByCurrentPriceLowToHigh(currentProductGroupList),
                );
            }
        }
    }, [currentProductGroupList]);

    useEffect(() => {
        if (brandProductsOnStage && currentProductGroupList) {
            if (sortByPrice === 'highToLow') {
                setCurrentProductList(
                    brandUtils.sortProductByCurrentPriceHighToLow(
                        discounted
                            ? currentProductGroupList.filter((item) => item.price)
                            : currentProductGroupList,
                    ),
                );
            }
            if (sortByPrice === 'lowToHigh') {
                setCurrentProductList(
                    brandUtils.sortProductByCurrentPriceLowToHigh(
                        discounted
                            ? currentProductGroupList.filter((item) => item.price)
                            : currentProductGroupList,
                    ),
                );
            }
        }
    }, [discounted, sortByPrice, currentProductGroupList, brandProductsOnStage]);

    const checkBrandDetailsHeaderHeight = () => {
        setBrandDetailsHeaderHeight(brandDetailsHeaderBind.current.getBoundingClientRect().height);
    };

    useEffect(() => {
        setBrandDetailsHeaderHeight(brandDetailsHeaderBind.current?.getBoundingClientRect().height);
    }, [brandDetailsHeaderBind.current]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', checkBrandDetailsHeaderHeight);
            return () => window.removeEventListener('resize', checkBrandDetailsHeaderHeight);
        }
        return null;
    }, []);

    return (
        <>
            <BrandDetailsHeaderContent ref={brandDetailsHeaderBind}>
                <BrandDetailsHeader>
                    <BrandDetailsHeaderMain
                        headerOffset={overlayActionsOffset}
                        productsAmount={currentProductList?.length}
                    />
                </BrandDetailsHeader>
            </BrandDetailsHeaderContent>
            <ProductListNoResults
                inProp={isEmpty(currentProductList)}
                headerOffset={brandDetailsHeaderHeight}
            />
            <TransitionItem appear inProp={!isEmpty(currentProductList)} mountOnEnter unmountOnExit>
                <ProductListFadeContainer headerOffset={brandDetailsHeaderHeight}>
                    <AutoSizer>
                        {({ height, width }) => {
                            return (
                                <VirtualizedProductList
                                    itemHeight={height}
                                    itemWidth={width}
                                    itemCount={currentProductList?.length}
                                    products={currentProductList}
                                />
                            );
                        }}
                    </AutoSizer>
                </ProductListFadeContainer>
            </TransitionItem>
        </>
    );
};

BrandDetailsContent.propTypes = {
    overlayActionsOffset: PropTypes.number,
};

BrandDetailsContent.defaultProps = {
    overlayActionsOffset: undefined,
};

export default BrandDetailsContent;

import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import slugify from 'slugify';
import { Formik, Form } from 'formik';
import { isEmpty, merge } from 'lodash';
import { H6 } from '@luigiclaudio/ga-baseline-ui/typography';
import withSizes from 'react-sizes';
import { isSizes } from '@luigiclaudio/ga-baseline-ui/layout';
import { TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import ProductFilterContainer from './ProductFilterContainer';
import ProductFilterSearch from './ProductFilterSearch';
import ProductFilterNoResults from './ProductFilterNoResults';
import allFilterTypes from '../../../data/allFilterTypes.json';
import ProductCatalogFilterList from '../productCatalogFilters/ProductCatalogFilterList';
import BrandContext from '../../context/BrandContext';
import useCurrentFilters from '../../../utils/useCurrentFilters';

const ProductCatalogAsideFilters = ({ isLg, reinitializeList }) => {
    if (isLg) {
        return null;
    }
    const [hasChanged, setHasChanged] = useState(reinitializeList);
    const [locationProductFilter, setLocationProductFilter] = useState();
    const [eneblaReinitialize, setEnableReinitialize] = useState(reinitializeList);
    const { productFilterList, productTypeListOnSelection, searchFilterTypes } = useContext(
        BrandContext,
    );
    const { currentProductFilter } = useCurrentFilters();

    useEffect(() => {
        setLocationProductFilter(currentProductFilter);
    }, [useCurrentFilters]);

    useEffect(() => {
        if (reinitializeList) {
            setEnableReinitialize(productFilterList ? hasChanged : false);
        }
    }, [reinitializeList, hasChanged, productFilterList]);

    useEffect(() => {
        setEnableReinitialize(!searchFilterTypes);
    }, [searchFilterTypes]);

    const productTypeOptions = productTypeListOnSelection.map((item) => {
        return { value: item, label: item.toLowerCase() };
    });

    const initialProductSearchFilter = {
        productSearchFilter: '',
    };

    const initialProductTypeArray = {};

    useEffect(() => {
        productTypeListOnSelection.forEach((item) => {
            initialProductTypeArray[`${slugify(item)}`] = productFilterList
                ? productFilterList[`${slugify(item)}`]
                : false;
        });
    }, []);

    return (
        <ProductFilterContainer>
            <Formik initialValues={initialProductSearchFilter} onSubmit={() => {}}>
                {() => {
                    return (
                        <Form noValidate>
                            <ProductFilterSearch onChange={() => setHasChanged(false)} />
                        </Form>
                    );
                }}
            </Formik>
            <TransitionItem appear inProp={isEmpty(productTypeOptions)} unmountOnExit mountOnEnter>
                <ProductFilterNoResults>
                    <H6 color="muted">
                        <FormattedMessage id="productFilterSearch.productFilter.noResults.label" />
                    </H6>
                </ProductFilterNoResults>
            </TransitionItem>
            <TransitionItem appear inProp={!isEmpty(productTypeOptions)} mountOnEnter>
                <Formik
                    enableReinitialize={eneblaReinitialize}
                    initialValues={{
                        productFilter: merge(initialProductTypeArray, locationProductFilter),
                    }}
                >
                    {() => {
                        return (
                            <Form noValidate>
                                {allFilterTypes.map((filter, index) => {
                                    const { type, items } = filter;
                                    const filterProductTypeOptions = productTypeOptions.filter(
                                        (item) => items.includes(item.value),
                                    );
                                    return (
                                        <ProductCatalogFilterList
                                            hasContainer
                                            key={index}
                                            type={type}
                                            typesAmount={allFilterTypes.length}
                                            filterProductTypeOptions={filterProductTypeOptions}
                                            onChange={() => {
                                                setHasChanged(true);
                                            }}
                                        />
                                    );
                                })}
                            </Form>
                        );
                    }}
                </Formik>
            </TransitionItem>
        </ProductFilterContainer>
    );
};

const mapSizesToProps = ({ width }, { theme }) => {
    return {
        isLg: isSizes.lgView({ width }, { theme }),
    };
};

ProductCatalogAsideFilters.propTypes = {
    isLg: PropTypes.bool,
    reinitializeList: PropTypes.bool,
};

ProductCatalogAsideFilters.defaultProps = {
    isLg: false,
    reinitializeList: false,
};

export default withSizes(mapSizesToProps)(ProductCatalogAsideFilters);

import styled from 'styled-components';
import { rem } from 'polished';
import scrollbarStyles from '../../../styles/scrollbarStyles';

const ProductFilterContainer = styled.div`
    bottom: 0;
    box-shadow: ${({ theme }) => theme.base.boxShadow.elevationDefault};
    height: ${({ theme }) =>
        `calc(100vh - ${theme.header.height + theme.site.brandSearch.height}px)`};
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: ${({ theme }) => `calc(${theme.header.height + theme.site.brandSearch.height}px)`};
    width: ${({ theme }) => rem(theme.site.productFilters.width)};
    z-index: 1;

    ${scrollbarStyles({ scrollbarOffset: 4 })}
`;

export default ProductFilterContainer;

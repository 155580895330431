import styled from 'styled-components';
import { rem } from 'polished';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';

const BrandDetailsOverlay = styled.div`
    background-color: ${({ theme }) => theme.site.stage.backgroundColor};
    bottom: 0;
    height: ${({ theme }) =>
        `calc(100vh - ${rem(theme.header.height + theme.site.brandSearch.height)} )`};
    left: 0;
    position: absolute;
    right: 0;
    top: ${({ theme }) => `calc(${rem(theme.header.height + theme.site.brandSearch.height)})`};
    width: 100vw;
    z-index: 0;

    ${media.lg`
        border-radius: ${({ theme }) => rem(theme.base.borderRadius.md)};
        box-shadow: ${({ theme }) => theme.base.boxShadow.elevationDefault};
        height: ${({ theme }) =>
            `calc(100vh - ${rem(
                theme.header.height +
                    theme.site.brandSearch.height +
                    theme.site.productCatalog.spacing,
            )})`};
        left: auto;
        margin: ${({ theme }) =>
            `${rem(theme.site.productCatalog.spacing / 2)} ${rem(
                theme.site.productCatalog.spacing,
            )}`};
        width: ${({ theme }) =>
            `calc(100vw - ${rem(
                theme.site.productFilters.width + theme.site.productCatalog.spacing * 2,
            )})`};
    `}
`;

export default BrandDetailsOverlay;

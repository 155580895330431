import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@luigiclaudio/ga-baseline-ui/modal';
import { Form, Formik } from 'formik';
import { isEmpty, merge } from 'lodash';
import slugify from 'slugify';
import { useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';
import allFilterTypes from '../../../data/allFilterTypes.json';
import ProductCatalogFilterList from '../productCatalogFilters/ProductCatalogFilterList';
import ModalProductCatalogFiltersContainer from '../productCatalogFilters/ModalProductCatalogFiltersContainer';
import ProductCatalogFiltersStatus from '../productCatalogFilters/ProductCatalogFiltersStatus';
import BrandContext from '../../context/BrandContext';
import ModalProductCatalogFiltersFadeContainer from './ModalProductCatalogFiltersFadeContainer';
import useCurrentFilters from '../../../utils/useCurrentFilters';

const ProductCatalogModalFilters = ({ onRequestClose, ...props }) => {
    const theme = useContext(ThemeContext);
    const { productFilterList, productTypeListOnSelection, productTypeOnStage } = useContext(
        BrandContext,
    );
    const intl = useIntl();
    const { currentProductFilter } = useCurrentFilters();

    const productTypeOptions = productTypeListOnSelection.map((item) => {
        return { value: item, label: item.toLowerCase() };
    });

    const initialProductTypeArray = {};

    const modalOffsetTop = theme.header.menuModal.offsetTop;
    const modalEntryTransitionY = theme.header.menuModal.entryTransitionY;

    useEffect(() => {
        productTypeListOnSelection.forEach((item) => {
            initialProductTypeArray[`${slugify(item)}`] = productFilterList
                ? productFilterList[`${slugify(item)}`]
                : false;
        });
    }, []);

    return (
        <Formik
            initialValues={{
                productFilter: merge(initialProductTypeArray, currentProductFilter),
            }}
        >
            {({ resetForm }) => {
                return (
                    <Modal
                        title={intl.formatMessage({
                            id: 'productFilter.productTypes.modal.filter.title',
                        })}
                        size="sm"
                        offsetTop={modalOffsetTop}
                        entryTransitionY={modalEntryTransitionY}
                        footerActions={[
                            {
                                label: intl.formatMessage({
                                    id: 'productFilter.productTypes.clearAll.label',
                                }),
                                skin: 'muted',
                                btnType: 'outlined',
                                onClick: () => {
                                    resetForm({
                                        values: { productFilter: {} },
                                    });
                                },
                                disabled: isEmpty(productTypeOnStage),
                            },
                            {
                                label: intl.formatMessage({
                                    id: 'productFilter.productTypes.showItems.label',
                                }),
                                skin: 'muted',
                                onClick: () => onRequestClose(),
                            },
                        ]}
                        footerPre={<ProductCatalogFiltersStatus headerOffset={16} />}
                        {...props}
                    >
                        <ModalProductCatalogFiltersFadeContainer>
                            <ModalProductCatalogFiltersContainer>
                                <Form noValidate>
                                    {allFilterTypes.map((filter, index) => {
                                        const { type, items } = filter;

                                        const filterProductTypeOptions = productTypeOptions.filter(
                                            (item) => items.includes(item.value),
                                        );

                                        return (
                                            <ProductCatalogFilterList
                                                key={index}
                                                type={type}
                                                filterProductTypeOptions={filterProductTypeOptions}
                                            />
                                        );
                                    })}
                                </Form>
                            </ModalProductCatalogFiltersContainer>
                        </ModalProductCatalogFiltersFadeContainer>
                    </Modal>
                );
            }}
        </Formik>
    );
};

ProductCatalogModalFilters.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    currentProductFilter: PropTypes.shape(),
};

ProductCatalogModalFilters.defaultProps = {
    currentProductFilter: undefined,
};

export default ProductCatalogModalFilters;

import styled from 'styled-components';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';

const ProductListSortFiltersContainer = styled.div`
    display: flex;
    /* flex-direction: column; */
    flex-direction: row;

    ${media.sm`
        flex-direction: row
    `}
`;

export default ProductListSortFiltersContainer;
